body {
  background-image: url(images/19366.jpg);
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
  font-family: "Georgia, serif";

}

h1 {
  color: #3876e9;
  font-size: 3em;
  text-align: center;
}

h2 {
  color: #3876e9;
  font-size: 25px;
  text-align: center;
}

h6 {
  color: #3876e9;
  font-size: 40px;
  text-align: center;
  margin: 0%;
}

.inputStyle {
  text-align: center;
}

.MuiSelect-select {
  color: #3876e9;
}

.MuiSelect-outlined {
  color: #3876e9;
}

.MuiOutlinedInput-input {
  color: #3876e9;
}

.MuiInputBase-input {
  color: #3876e9;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: #3876e9;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;

}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 20px;
  font-size: 20px;
  border-left: none;
  border-top: none;
  border-right: none;
}

.colorchange {
  color: orange;
}

.space {
  margin-top: 80px;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

.centerTable {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}

.searchVaccine {
  text-align: center;
}

.centerButton {
  text-align: center;
}

p {
  font-size: 30px;
}

.space40 {
  margin-top: 40px;
}


.app-text {
  font-size: 20px;
}

.SSN {
  font-size: 25px;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.imgSize {
  height: 100px;
  width: 100px;
}


ul.b {
  list-style-type: square;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}